import React from "react";
import classNames from "classnames";
import { Text, Container, Button, AnimateIn } from "@atoms";

const SectionHeading = ({
  bgColor,
  descriptor,
  heading,
  paddingBottom,
  paddingTop,
  showDivider,
  button,
}) => {
  return (
    <section
      className={classNames("pt-20", {
        "md:pt-28": paddingTop || showDivider,
        "pb-20 md:pb-28": paddingBottom,
        "border-t-2 border-black": showDivider,
        "bg-gray": bgColor === "gray",
        "bg-green": bgColor === "green",
        "bg-white": bgColor === "white",
      })}
    >
      <Container>
        <AnimateIn
          preset="fadeUpFast"
          className="flex flex-wrap gap-6 sm:gap-20 md:flex-nowrap"
        >
          <div
            className={classNames(
              "w-full flex-shrink-0 sm:w-1/3 md:whitespace-pre-line",
              {
                "md:w-2/5": descriptor,
                "md:w-1/2": !descriptor,
              }
            )}
          >
            <Text variant="h2">{heading}</Text>
          </div>
          <div className="flex flex-1 flex-col gap-5">
            <Text
              className={classNames(
                "block text-2xl leading-tight md:text-3xl",
                {
                  "sm:pt-[.55rem] md:pt-4": heading,
                }
              )}
            >
              {descriptor}
            </Text>
            {button?.url && (
              <div>
                <Button
                  to={button.url}
                  download={button.download || button.type === "asset"}
                >
                  {button.text}
                </Button>
              </div>
            )}
          </div>
        </AnimateIn>
      </Container>
    </section>
  );
};

SectionHeading.defaultProps = {
  bgColor: "white",
  paddingBottom: true,
  divider: false,
};

export default SectionHeading;
